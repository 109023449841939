<style scoped>
    #search:focus+#list {
        display: flex;
    }

    .dp__input {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }

    #search:not(focus)+.lister {
        border: none;
    }

    .searcher:focus {
        border-bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    #list:hover {
        display: flex;
    }

    .dp__main {
        padding: 0 !important
    }
</style>

<template>
    <Transition name="slide-fade" appear>
        <div>
            <div class="blur" style="z-index: -1; opacity: 0;"></div>
            <img src="https://images.unsplash.com/photo-1566622246615-6dbe9c71629a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80"
                class="fixed  " style="height: 100vh; width: 100vw; object-fit: cover;" alt="">
            <div class="fixed w-full h-full bg-t3  " style="opacity:.8;" alt=""></div>
            <div class="relative w-full h-screen mb-3 " style="min-height: 100vh;">

                <nav class="navbar navbar-expand-lg  w-full p-2 md:p-8 md:w-5/6 2xl:w-4/6 md:mx-auto"
                    aria-label="Ninth navbar example">
                    <div class="container mx-auto row justify-between flex items-center">
                        <div class="flex-auto  col md:text-start text-center  ">
                            <img class="mb-2 " src="../assets/white-transparent-logo.svg" alt=""
                                style="width:200px; max-width: 200px !important;" />
                        </div>
                        <div class=" col justify-end">

                            <div>

                            </div>
                        </div>

                    </div>
                </nav>
                <main class="w-full p-2 md:p-8 md:w-5/6 2xl:w-4/6 md:mx-auto">


                    <div class="w-full pt-6 m-auto text-t3 container">
                        <div class="w-full pb-6 flex mx-auto justify-between items-center">
                            <div class="text-center space-y-2 md:space-y-0  md:flex items-center gap-1"
                                :class="step === 1?'text-white':'text-t3-lighter'">
                                <button @click="step = 1; miss1=false" class="rounded-full w-8 h-8 "
                                    :class="step === 1?'bg-white text-t3':'bg-t3-lighter text-t3-lightest'">1</button>
                                <p class="2xl:text-base text-xs md:text-sm " :class="{'text-red-500 ': miss1}">Personal
                                    Details</p>
                            </div>
                            <hr class="color-gray-300 mx-2  flex-auto">
                            <div class="text-center space-y-2 md:space-y-0 md:flex items-center gap-1"
                                :class="step === 2?'text-white':'text-t3-lighter'">
                                <button @click="step = 2; miss2=false" class="rounded-full w-8 h-8 "
                                    :class="step === 2?'bg-white text-t3':'bg-t3-lighter text-t3-lightest'">2</button>
                                <p class="2xl:text-base text-xs md:text-sm " :class="{'text-red-500 ': miss2}">Book Slot
                                </p>
                            </div>
                            <hr class="color-gray-300 mx-2  flex-auto">
                            <div class="text-center space-y-2 md:space-y-0 md:flex items-center gap-1"
                                :class="step === 3?'text-white':'text-t3-lighter'">
                                <button @click="step = 3; miss3=false" class="rounded-full w-8 h-8 "
                                    :class="step === 3?'bg-white text-t3':'bg-t3-lighter text-t3-lightest'">3</button>
                                <p class="2xl:text-base text-xs md:text-sm " :class="{'text-red-500 ': miss3}">Upload
                                    Documents</p>
                            </div>
                        </div>
                        <div class=" rounded container items-center min-h-screen relative md:mt-0 pb-12">
                            <transition name="slide-fade">

                                <div v-if="step=== 1" class="p-12  bg-white rounded container absolute ">
                                    <div class="md:w-5/6">

                                        <p class="text-sm md:text-base">
                                            Please ensure the information you provide is precise, as it will be used for
                                            the certificate at a later stage.
                                        </p>
                                        <div
                                            class="flex flex-col md:grid md:grid-cols-3 gap-2 m-auto mt-6 items-center ">

                                            <div class="space-y-4 col-span-2">

                                                <div class="form-floating ">
                                                    <input v-model="full_name" @input="split" type="text"
                                                        class="form-control w-full m-0 px-4 py-1 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-t3-lights rounded transition ease-in-out focus:outline-none"
                                                        placeholder="First Name" id="floatingInput1" />
                                                    <label for="floatingInput1" class="text-t3-lighter">Full Name (as
                                                        per passport)<span class="text-red-600">*</span></label>
                                                </div>
                                                <div class="form-floating  ">
                                                    <input v-model="user.phone_number" type="text"
                                                        class="form-control block w-full m-0 px-4 py-1 text-xl font-normal text-base text-gray-700 bg-white bg-clip-padding border border-solid border-t3-lights rounded transition ease-in-out focus:outline-none"
                                                        placeholder="Mobile Number" id="floatingInput2" />
                                                    <label for="floatingInput2" class="text-t3-lighter">Mobile
                                                        Number<span class="text-red-600">*</span></label>
                                                </div>

                                                <div class="form-floating  col-span-2">
                                                    <input v-model="user.email" type="email"
                                                        class="form-control w-full m-0 px-4 py-1 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-t3-lights rounded transition ease-in-out focus:outline-none"
                                                        placeholder="example@email.com" id="floatingInput4" />
                                                    <label for="floatingInput4" class="text-t3-lighter">Email
                                                        Address<span class="text-red-600">*</span></label>
                                                </div>
                                            </div>
                                            <div class="text-center ">
                                                <img v-if="user.profile_img" id='output-image'
                                                    class="w-48 h-64 m-auto border-t3-secondary border  object-cover rounded-xl"
                                                    alt="" />
                                                <label v-if="!user.profile_img"
                                                    class="w-48 h-64 flex hover:bg-t3-mutedText hover:text-white text-t3-lighter bg-t3-lightest m-auto flex-col items-center justify-center px-4 py-12 bg-sky-200  rounded-lg tracking-wide  cursor-pointer hover:border-t3-teritiary hover:text-white-teritiary">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                        viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                                                        class="w-16 h-16">
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                            d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                                                    </svg>

                                                    <span class="mt-2 text-base leading-normal">Upload passport size
                                                        photo</span>
                                                    <FileInput id="inputfield" ref="fileupload" accept="image/*"
                                                        @change="attachmentLoad" v-model="user.profile_img"
                                                        class="hidden" />
                                                </label>
                                                <button @click="user.profile_img = null" v-if="user.profile_img"
                                                    class="text-sm text-t3-cancel mt-2">X Pick Another</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="space-y-2 text-center md:text-left text-sm md:text-base text-red-500 mt-16">
                                        <p v-if="miss1">Please make sure to enter all your pesonal details.</p>
                                        <p v-if="miss2">Please choose a slot to book for your assessment.</p>
                                        <p v-if="miss3">Please upload all the required documents.</p>

                                    </div>
                                    <div class="w-full justify-center md:justify-end gap-2 md:gap-4  flex">

                                        <button v-if="step > 1 && step < 4"
                                            @click="()=>{step--;  if(step ===1){miss1=false;} else{miss2=false}}"
                                            class=" w-full md:w-48  mt-4 rounded-md font-medium flex justify-center items-center gap-2 bg-gray-200 p-3 text-t3  hover:bg-gray-300 block">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                    d="M15.75 19.5L8.25 12l7.5-7.5" />
                                            </svg>

                                            <p>Previous</p>
                                        </button>
                                        <button v-if="step < 3"
                                            @click="()=>{step++; if(step ===2){miss2=false;} else{miss3=false}}"
                                            class=" w-full md:w-48  mt-4 rounded-md font-medium flex justify-center items-center gap-2 bg-t3 p-3 text-white hover:text-white hover:bg-t3-secondary block">
                                            <p>Next</p>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                    d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                            </svg>

                                        </button>
                                        <button v-if="step === 3" @click="addUser()"
                                            class=" w-full md:w-48  mt-4 rounded-md font-medium flex justify-center items-center gap-2 bg-t3 p-3 text-white hover:text-white hover:bg-t3-secondary block">Finish
                                            Booking</button>
                                        <!-- <button @click="addUser()" class=" w-48  mt-4 rounded-md font-medium bg-t3 p-3 text-white hover:text-white hover:bg-t3-secondary block" >Save User Details</button> -->
                                    </div>

                                </div>

                            </transition>
                            <transition name="slide-fade">
                                <div v-if="step=== 2" class="mb-16 p-12  bg-white rounded container absolute ">
                                    <div class="md:w-5/6">

                                        <div class=" relative">
                                            <p class="text-sm md:text-base mb-4 ">
                                                When would you prefer to schedule the assessment
                                            </p>
                                            <label for="datepick" class="text-sm text-gray-400 mt-8">Highlighted dates
                                                have available slots </label>
                                            <Datepicker id="datepick" :min-date="new Date()"
                                                @update:modelValue="newSlots" v-model="assessDate"
                                                :highlight="highlighted" :enableTimePicker="false" datePicker
                                                placeholder="Select Date"
                                                class="mb-8 form-control block w-full text-xl font-normal text-gray-700 bg-white rounded transition ease-in-out m-0 focus:outline-none" />
                                            <p class="text-sm md:text-base mb-4 ">
                                                Choose from the available slots in the selected date
                                            </p>
                                            <div class="flex flex-wrap  w-full gap-6 ">
                                                <!-- <div class="rounded-full py-2 px-3 bg-gray-300 hover:bg-t3-secondary hover:text-white">{{slot.start_time}}</div> -->
                                                <button v-for="slot in freeSlots" :key="slot.id"
                                                    @click="user.slot=slot.id"
                                                    class="rounded-full py-2 px-6 border border-t3-lighter hover:bg-t3-secondary hover:text-white"
                                                    :class="{'bg-t3 border-t3 text-white': user.slot === slot.id }">{{slot.start_time}}</button>

                                                <p v-if="freeSlots.length === 0" class="text-t3-mutedText ">The
                                                    requested date has no available slots.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="space-y-2 text-center md:text-left text-sm md:text-base text-red-500 mt-16">
                                        <p v-if="miss1">Please make sure to enter all your pesonal details.</p>
                                        <p v-if="miss2">Please choose a slot to book for your assessment.</p>
                                        <p v-if="miss3">Please upload all the required documents.</p>

                                    </div>
                                    <div class="w-full justify-center md:justify-end gap-2 md:gap-4  flex">

                                        <button v-if="step > 1 && step < 4"
                                            @click="()=>{step--;  if(step ===1){miss1=false;} else{miss2=false}}"
                                            class=" w-full md:w-48  mt-4 rounded-md font-medium flex justify-center items-center gap-2 bg-gray-200 p-3 text-t3  hover:bg-gray-300 block">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                    d="M15.75 19.5L8.25 12l7.5-7.5" />
                                            </svg>

                                            <p>Previous</p>
                                        </button>
                                        <button v-if="step < 3"
                                            @click="()=>{step++; if(step ===2){miss2=false;} else{miss3=false}}"
                                            class=" w-full md:w-48  mt-4 rounded-md font-medium flex justify-center items-center gap-2 bg-t3 p-3 text-white hover:text-white hover:bg-t3-secondary block">
                                            <p>Next</p>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                    d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                            </svg>

                                        </button>
                                        <button v-if="step === 3" @click="addUser()"
                                            class=" w-full md:w-48  mt-4 rounded-md font-medium flex justify-center items-center gap-2 bg-t3 p-3 text-white hover:text-white hover:bg-t3-secondary block">Finish
                                            Booking</button>
                                        <!-- <button @click="addUser()" class=" w-48  mt-4 rounded-md font-medium bg-t3 p-3 text-white hover:text-white hover:bg-t3-secondary block" >Save User Details</button> -->
                                    </div>
                                </div>
                            </transition>
                            <transition name="slide-fade">
                                <div v-if="step=== 3" class="mb-16 p-12  bg-white rounded  container absolute ">
                                    <div class="md:w-5/6">

                                        <div class=" relative">
                                            <p class="text-sm md:text-base mb-4 ">
                                                Are you an employee of one of these Organizations?
                                            </p>
                                            <div class="flex flex-wrap  w-full gap-6 mb-4">
                                                <select v-model="user.customer_company" :disabled="editProf" @change="handleCompanyChange"
                                                    class="hover:bg-t3-lightest w-full md:w-1/2 hover:bg-t3-lightest  hover:text-t3-secondary border cursor-pointer text-t3-lighter items-center p-4 flex  border-t3-lighter bg-white rounded justify-between">
                                                    <option :value="null"> Select Company ...</option>
                                                    <option v-for="organization in organizations" :key="organization.id"
                                                        :value="organization.id">{{organization.organization_name}}
                                                    </option>
                                                    <option :value="null"> None of the above</option>
                                                </select>
                                            </div>
                                            <p class="text-sm md:text-base mb-4 ">
                                                Are you a member or afilliated with any of these organizations? Choose
                                                all that apply
                                            </p>
                                            <div class="flex flex-wrap  w-full gap-6 mb-4 ">
                                                <div class="w-full md:w-1/2 flex flex-col items-center ">
                                                    <div class="w-full ">
                                                        <div class="flex flex-col items-center relative">
                                                            <div class="w-full  ">
                                                                <div @click="showAff = !showAff"
                                                                    class="hover:bg-t3-lightest pr-1 hover:bg-t3-lightest  hover:text-t3-secondary border cursor-pointer text-t3-lighter items-center p-4 flex  border-t3-lighter bg-white rounded justify-between ">
                                                                    <p>{{ user.other_affiliations.length }} Selected</p>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                        viewBox="0 0 24 24" stroke-width="3"
                                                                        stroke="currentColor" class="w-3 h-3 transform"
                                                                        :class="{'rotate-180':showAff}">
                                                                        <path stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                                    </svg>

                                                                </div>
                                                            </div>
                                                            <div v-if="showAff" class="shadow top-16 bg-white z-40 w-full absolute rounded max-h-56 overflow-y-scroll svelte-5uyqqj">
                                                                <div @click="editChange(organization.id)" v-for="organization in organizations" :key="organization.id" class="flex flex-col w-full">
                                                                    <div class="cursor-pointer w-full border-gray-100 rounded-t border-b hover:bg-t3-body hover:text-t3-secondary">
                                                                        <div class="flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative hover:border-teal-100">
                                                                            <div class="w-full items-center flex">
                                                                                <input
                                                                                    :checked=" user.other_affiliations.find(a => a === organization.id)"
                                                                                    class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                                                                    type="checkbox"
                                                                                    id="flexCheckDefault" />
                                                                                <div class="mx-2 leading-9  ">
                                                                                    {{organization.organization_name}}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            
                                            <p class="text-sm md:text-base mb-4 ">
                                                Upload the required documents below
                                            </p>
                                            <div
                                                class="space-y-3 md:space-y-0 md:grid grid-cols-3 gap-4 items-center  mb-4">

                                                <p class="text-sm md:text-base mb-4 min-w-max ">
                                                    Passport copy:<span class="text-red-600">*</span>
                                                </p>
                                                <label
                                                    class="col-span-2 w-full flex border border-t3-lighter m-auto flex-col   px-6 py-3 bg-sky-200 text-t3-lighter  rounded tracking-wide  cursor-pointer hover:border-t3-secondary hover:text-t3-secondary">
                                                    <FileInput id="inputfield" ref="fileupload"
                                                        accept="image/*,application/pdf" v-model="document1"
                                                        class="hidden" />
                                                    <p v-if="!document1">Add file here...</p>
                                                    <p v-if="document1">{{document1.name}}</p>
                                                </label>


                                                <p class="text-sm md:text-base mb-4 min-w-max ">
                                                    License copy:
                                                </p>
                                                <label
                                                    class="col-span-2 w-full flex border border-t3-lighter m-auto flex-col   px-6 py-3 bg-sky-200 text-t3-lighter  rounded tracking-wide  cursor-pointer hover:border-t3-secondary hover:text-t3-secondary">
                                                    <FileInput id="inputfield" ref="fileupload"
                                                        accept="image/*,application/pdf" v-model="document2"
                                                        class="hidden" />
                                                    <p v-if="!document2">Add file here...</p>
                                                    <p v-if="document2">{{document2.name}}</p>
                                                </label>

                                                <p v-if="discount_checker" class="text-sm md:text-base mb-4 min-w-max ">
                                                     File for Discount:
                                                </p>
                                                <label v-if="discount_checker"
                                                    class="col-span-2 w-full flex border border-t3-lighter m-auto flex-col   px-6 py-3 bg-sky-200 text-t3-lighter  rounded tracking-wide  cursor-pointer hover:border-t3-secondary hover:text-t3-secondary">
                                                    <FileInput id="inputfield" ref="fileupload"
                                                        accept="image/*,application/pdf" v-model="document3"
                                                        class="hidden" />
                                                    <p v-if="!document3">Add file here...</p>
                                                    <p v-if="document3">{{document3.name}}</p>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="space-y-2 text-center md:text-left text-sm md:text-base text-red-500 mt-16">
                                        <p v-if="miss1">Please make sure to enter all your pesonal details.</p>
                                        <p v-if="miss2">Please choose a slot to book for your assessment.</p>
                                        <p v-if="miss3">Please upload all the required documents.</p>
                                    </div>
                                    <div class="w-full justify-center md:justify-end gap-2 md:gap-4  flex">
                                        <button v-if="step > 1 && step < 4"
                                            @click="()=>{step--;  if(step ===1){miss1=false;} else{miss2=false}}"
                                            class=" w-full md:w-48  mt-4 rounded-md font-medium flex justify-center items-center gap-2 bg-gray-200 p-3 text-t3  hover:bg-gray-300 block">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                    d="M15.75 19.5L8.25 12l7.5-7.5" />
                                            </svg>
                                            <p>Previous</p>
                                        </button>
                                        <button v-if="step < 3"
                                            @click="()=>{step++; if(step ===2){miss2=false;} else{miss3=false}}"
                                            class=" w-full md:w-48  mt-4 rounded-md font-medium flex justify-center items-center gap-2 bg-t3 p-3 text-white hover:text-white hover:bg-t3-secondary block">
                                            <p>Next</p>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                    d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                            </svg>
                                        </button>
                                        <button v-if="step === 3" @click="addUser()"
                                            class=" w-full md:w-48  mt-4 rounded-md font-medium flex justify-center items-center gap-2 bg-t3 p-3 text-white hover:text-white hover:bg-t3-secondary block">
                                            Finish Booking</button>
                                        <!-- <button @click="addUser()" class=" w-48  mt-4 rounded-md font-medium bg-t3 p-3 text-white hover:text-white hover:bg-t3-secondary block" >Save User Details</button> -->
                                    </div>
                                </div>
                            </transition>
                            <transition name="slide-fade">
                                <div v-if="step=== 4" class="p-12  bg-white rounded w-full">
                                    <div class="text-xl w-full font-semibold text-center text-t3 my-64">
                                        <PulseLoader :loading="loading" color="#00ADEE" size="16px" class="mb-6">
                                        </PulseLoader>
                                        <p>Sending your request...</p>

                                    </div>

                                </div>
                            </transition>

                        </div>
                    </div>

                </main>

            </div>
        </div>


    </Transition>

</template>

<script>
    import FileInput from '@/components/FileInput.vue';
    import ReportServices from '@/services/ReportServices';
    import Datepicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css';
    import addDays from 'date-fns/addDays';
    import Swal from 'sweetalert2';
    import {
        ref,
        watchEffect
    } from 'vue';
    import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
    import {
        mapActions,
        mapGetters,
        mapState
    } from 'vuex';
    import UsersServices from '../services/UsersServices';

    export default {
        components: {
            Datepicker,
            FileInput,
            PulseLoader
        },
        setup() {
            const date = ref(new Date());
            const highlightedDates = ref([
                addDays(new Date(), 1),
                addDays(new Date(), 2),
                addDays(new Date(), 3),
            ])

            return {
                date,
                highlightedDates

            }
        },
        props: {
            loaded: {},
            id: {}
        },
        data() {
            return {
                checker: false,
                selected: null,
                currentFile: null,
                highlighted: [],
                freeSlots: [],
                assessDate: new Date,
                full_name: null,
                step: 1,
                showAff: false,
                miss1: false,
                miss2: false,
                miss3: false,
                discount_checker:true,
                user: {
                    slot: null,
                    email: null,
                    profile_img: null,
                    first_name: null,
                    middle_name: null,
                    last_name: null,
                    customer_company: null,
                    phone_number: null,
                    other_affiliations: [],
                },
                document1: null,
                document2: null,
                document3: null
            }
        },

        methods: {
            ...mapActions({
                getorganizations: 'Settings/getAllOrganizations',
                gettypes: 'Settings/getAllCustomerTypes',
                getbooked: 'Requests/getBookings',
                getslots: 'Slots/getSlotsForDate',
                getallslots: 'Slots/getAllSlots',
            }),
            newSlots(data) {
                this.user.slot = null
                if (data) {

                    this.assessDate = data
                    let dat = this.assessDate.toISOString()
                    this.getslots(dat.substr(0, 10)).then(() => {
                        if (this.slots) {
                            this.freeSlot = this.slots.filter(slot => slot.assessment === null)
                        }
                    })
                }
            },
            disabledAfterToday(date) {
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                return date > today
            },
            attachmentLoad(event) {
                var reader = new FileReader();
                reader.onload = function () {
                    var output = document.getElementById('output-image');
                    output.setAttribute("src", URL.createObjectURL(event.target.files[0]))
                    document.getElementById('output-image').load();
                }
                reader.readAsDataURL(event.target.files[0]);

                this.currentFile = event.target.value;

            },

            handleCompanyChange() {
                if (this.user.customer_company === null) {
                    this.discount_checker = false;
                } else {
                    this.discount_checker = true;
                }
            },
            split() {
                let nameA = this.full_name.split(' ')
                this.user.first_name = nameA[0]
                this.user.last_name = nameA[nameA.length - 1]
                this.user.middle_name = nameA.slice(1, nameA.length - 1).toString().replaceAll(',', ' ')

            },
            editChange(id) {

                if (this.user.other_affiliations.includes(id)) {
                    this.user.other_affiliations.splice(this.user.other_affiliations.indexOf(id), 1)
                } else {
                    this.user.other_affiliations.push(id)

                }

            },
            addUser() {
                this.checker = true

                for (var i in this.user) {
                    if ((i !== "customer_company") && (i !== "middle_name")) {
                        if (this.user[i] === null || this.user[i] === "") {
                            this.checker = false
                            if (i === 'slot') {
                                this.miss2 = true
                            } else {
                                this.miss1 = true
                            }
                        }
                    }

                }
                if (this.document1 === null) {
                    this.checker = false
                    this.miss3 = true
                }
                if (this.checker) {

                    this.step = 4
                    const getFormData = object => Object.entries(object).reduce((fd, [key, val]) => {
                        if (Array.isArray(val)) {
                            val.forEach(v => fd.append(key, v))
                        } else {
                            fd.append(key, val)
                        }
                        return fd
                    }, new FormData())
                    let sendObj = {
                        ...this.user,
                        documents: [this.document1, this.document2, this.document3],
                    };
                    Object.keys(sendObj).forEach(key => sendObj[key] === null && delete sendObj[key]);
                    UsersServices.requestAdd(getFormData(sendObj)).then(() => {
                        Swal.fire({
                            icon: "success",
                            title: "Success!",
                            text: "Your booking request has been successfully submitted and it's under process. You will receive a confirmation once our team processes your request.",
                            showConfirmButton: false,
                            timerProgressBar: true,
                            timer: 3000,
                        })

                        this.$router.go('')
                    })
                    
                    .catch((error) => {
                        let message = Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
                        let customer_message = error.response.data[Object.keys(error.response.data).at(0)];

                        const errorMessage = "customer enquiry with this email already exists.";

                        if (customer_message.includes(errorMessage)) {
                            Swal.fire({
                                icon: "warning",
                                title: "Duplicate Inquiry",
                                text: "It appears you've already submitted a request. You will receive a conformation soon from us as we are processing your inquiry.",
                                showConfirmButton: true,
                                confirmButtonColor: "#0E2D5B",
                                confirmButtonText: "OK",
                            });
                            this.step = 3
                        } else {
                            Swal.fire({
                            icon: "error",
                            title: "It appears there was an error!",
                            text: message,
                            showConfirmButton: true,
                            showDenyButton: true,
                            confirmButtonColor: '#0E2D5B',
                            denyButtonColor: '#990c53',
                            denyButtonText: "Ignore",
                            confirmButtonText: "Send Report",
                        }).then((result) => {
                            if (result.value) {

                                let user = JSON.parse(localStorage.getItem('userdetail'))
                                ReportServices.sendErrorReport({
                                    error_page: window.location.href,
                                    error_api: error.response.config.url,
                                    error_message: message,
                                    email: user.email
                                })
                            }
                        })
                        this.step = 3
                    }})
                } else {

                    Swal.fire({
                        icon: "warning",
                        title: "Try again",
                        text: "Please fill in all the required fields.",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: 3000,
                    })

                }
            }

        },

        computed: {
            ...mapState({
                organizations: state => state.Settings.organizations,
                types: state => state.Settings.customer_types,
                allSlots: state => state.Slots.slots,
                slots: state => state.Slots.slotD,
                booked: state => state.Requests.bookings,
                customers: state => state.Users.customers
            }),
            ...mapGetters('Users', [
                'customerOnly'
            ])
        },

        created() {
            watchEffect(() => {
                if (this.assessDate) {
                    let dat = this.assessDate.toISOString()
                    this.getslots(dat.substr(0, 10)).then(() => {
                        this.freeSlots = this.slots.filter(slot => slot.assessment === null)

                    })
                }
                if (this.allSlots) {
                    this.highlighted = []
                    this.allSlots.filter(slot => slot.assessment === null).forEach((s) => {
                        this.highlighted.push(new Date(s.date))
                    })
                }



            })
            let dat = this.assessDate.toISOString()
            this.getslots(dat.substr(0, 10)).then(() => {
                if (this.slots) {
                    this.freeSlot = this.slots.filter(slot => slot.assessment === null)
                }
            })
            this.getallslots()
            this.getbooked()
            this.getorganizations()
            this.gettypes()
        },
    }
</script>